@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@500&family=Dancing+Script:wght@600;700&family=Great+Vibes&family=Poppins&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    font-family: 'Dancing Script', cursive;
    font-weight: 600;
  }
  ul {
    font-family: 'Dancing Script', cursive;
  }
}
